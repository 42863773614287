import React, { FC, useEffect, useState } from 'react'
import { Container, Box, Stack, useBreakpointValue } from '@chakra-ui/react'
import { H3Title } from 'src/uikit'

interface Props {
  title: string
}

const Reviews: FC<Props> = ({ title }) => {
  const [isMounted, setMount] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false })

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return <Box bg="baseWhite" py="xxl6">
    <Container as={Stack} maxW="6xl" textAlign="center">
      <H3Title>{title}</H3Title>
      <Box textAlign="center">
        {isMobile ?
          <div
            className="trustpilot-widget"
            data-locale="it-IT"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="5c8a76f8be6c490001d04ee0"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="light"
            data-text-color="#262626"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-allow-robots="true"
          >
            <a href="#"> </a>
          </div>
          :
          <div
            className="trustpilot-widget"
            data-locale="it-IT"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5c8a76f8be6c490001d04ee0"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="light"
            // data-font-family="Montserrat-Medium"
            data-text-color="#262626"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-allow-robots="true"
          >
            <a href="#"> </a>
          </div>
        }
      </Box>
    </Container>
  </Box>
}

export default Reviews
